import { Injectable } from "@angular/core";

declare var $: any;

@Injectable()
export class NotificationService {
  // Notification message
  notificationMessage(type, message) {
    $.notify(message, {
      type: type,
      allow_dismiss: true,
      newest_on_top: true,
      mouse_over: false,
      showProgressbar: false,
      timer: 2000,
      placement: {
        from: "top",
        align: "right"
      },
      delay: 1000,
      z_index: 10000,
      animate: {
        enter: "animated " + "fadeInUp",
        exit: "animated " + "fadeOutDown"
      }
    });
  }
}
