import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "./../../../../modules/public/login/login.service";
import { MessageService } from "./../../../../shared/services/message.service";
import { AdminLayoutService } from "../admin-layout.service";
import { TokenInformationService } from "./../../../../shared/services/token-information.service";

@Component({
  selector: "app-admin-header",
  templateUrl: "./admin-header.component.html",
  styleUrls: ["./admin-header.component.scss"]
})
export class AdminHeaderComponent implements OnInit {
  id: string;
  url: string;
  name: string;

  constructor(
    private _router: Router,
    private _loginService: LoginService,
    private _adminService: AdminLayoutService,
    private _messageService: MessageService,
    private _tokenService: TokenInformationService
  ) {
    const tokenData = this._tokenService.getTokenInformation();
    this.id = tokenData.id;
  }

  ngOnInit() {
    this.initialize();
    this.showProfileDetails(this.id);
    this.showUpdatedName();
  }

  // Initialize
  initialize() {
    this.name = "";
  }

  // Show profile details
  showProfileDetails(id) {
    this._adminService.showProfileDetails(id).subscribe(data => {
      if (data.status == true) {
        const first_name = data.data.first_name;
        const last_name = data.data.last_name;
        this.name = first_name + " " + last_name;
      }
    });
  }

  // Logout
  logout() {
    this._loginService.logout();
    const message = {
      status: "success",
      message: "Successfully logged out."
    };
    this._messageService.sendMessage(message);
    this._router.navigate(["/login"]);
  }

  // Show name in header when profile is updated
  showUpdatedName() {
    this._messageService.messageData.subscribe(message => {
      if (message != null) {
        this.name = message;
      }
    });
  }
}
