import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "./../../modules/public/login/login.service";
import { MessageService } from "./../../shared/services/message.service";

declare var $: any;

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"]
})
export class AdminComponent implements OnInit {
  isMobile: boolean;

  constructor(
    private _router: Router,
    private _loginService: LoginService,
    private _messageService: MessageService
  ) {}

  ngOnInit() {
    this.initialize();
    this.checkMobileDevice();
  }

  // Initialize
  initialize() {
    this.isMobile = false;
  }

  // Check whether the page is visited from mobile device or not
  checkMobileDevice() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  // Navigate to pages on clicking options in side menu
  navigateToPage(path) {
    const url = "/" + path;
    if (this.isMobile == true) {
      const hasClass = $("#sidenav-collapse-main").hasClass("show");
      if (hasClass == true) {
        $("#sidenav-collapse-main").removeClass("show");
      }
    } else {
      // this._router.navigate([url]);
    }
  }

  // Logout
  logout() {
    this._loginService.logout();
    const message = {
      status: "success",
      message: "Successfully logged out."
    };
    this._messageService.sendMessage(message);
    this._router.navigate(["/login"]);
  }
}
