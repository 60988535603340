import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { config } from "../../../config/config";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class AdminLayoutService {
  private server = config.server;
  private showProfileDetailsURL = this.server + "login/";
  private showDoctorCountURL = this.server + "doctors/count";
  private showFacilityCountURL = this.server + "facilities/count";
  private showServiceCountURL = this.server + "services/count";
  private showMessageCountURL = this.server + "messages/count";

  constructor(private _http: HttpClient) {}

  // Show profile details
  showProfileDetails(id): Observable<any> {
    const url = this.showProfileDetailsURL.trim() + id.trim();
    return this._http.get<any>(url, httpOptions);
  }

  // Show doctor count
  showDoctorCount(): Observable<any> {
    return this._http.get<any>(this.showDoctorCountURL, httpOptions);
  }

  // Show facility count
  showFacilityCount(): Observable<any> {
    return this._http.get<any>(this.showFacilityCountURL, httpOptions);
  }

  // Show service count
  showServiceCount(): Observable<any> {
    return this._http.get<any>(this.showServiceCountURL, httpOptions);
  }

  // Show message count
  showMessageCount(): Observable<any> {
    return this._http.get<any>(this.showMessageCountURL, httpOptions);
  }
}
