import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { config } from "../../../config/config";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class LoginService {
  private server = config.server;
  private signUpURL = this.server + "login";

  constructor(private _http: HttpClient, private _router: Router) {}

  // Send login data
  login(data): Observable<any> {
    return this._http.post<any>(this.signUpURL, data, httpOptions);
  }

  // Check whether user already logged in
  isLoggedIn() {
    const token = localStorage.getItem("admin_user");
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  // Logout the user & remove data from local storage
  logout() {
    localStorage.removeItem("admin_user");
  }
}
