import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class StatisticsCountService {
  // Doctor counter
  private doctorSource = new BehaviorSubject(null);
  doctorData = this.doctorSource.asObservable();

  sendDoctorCount(data: any) {
    this.doctorSource.next(data);
  }

  // Facility counter
  private facilitySource = new BehaviorSubject(null);
  facilityData = this.facilitySource.asObservable();

  sendFacilityCount(data: any) {
    this.facilitySource.next(data);
  }

  // Service counter
  private serviceSource = new BehaviorSubject(null);
  serviceData = this.serviceSource.asObservable();

  sendServiceCount(data: any) {
    this.serviceSource.next(data);
  }

  // Message counter
  private messageSource = new BehaviorSubject(null);
  messageData = this.messageSource.asObservable();

  sendMessageCount(data: any) {
    this.messageSource.next(data);
  }
}
