import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MessageService {
  // Pass messages in between components
  private messageSource = new BehaviorSubject(null);
  messageData = this.messageSource.asObservable();

  sendMessage(data: any) {
    this.messageSource.next(data);
  }
}
