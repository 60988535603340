import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root"
})
export class TokenInformationService {
  constructor() {}

  // Provide details about the token stored in local storage (id, expiry date & is expired)
  getTokenInformation() {
    const token = localStorage.getItem("admin_user");
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const expirationDate = helper.getTokenExpirationDate(token);
    const isExpired = helper.isTokenExpired(token);

    const token_info = {
      id: decodedToken.id,
      expiry_date: expirationDate,
      is_expired: isExpired
    };
    return token_info;
  }
}
