import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { NotFoundComponent } from "./modules/admin/not-found/not-found.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "login",
    loadChildren: "./modules/public/login/login.module#LoginModule"
  },
  {
    path: "sign-up",
    loadChildren: "./modules/public/sign-up/sign-up.module#SignUpModule"
  },
  {
    path: "forgot-password",
    loadChildren:
      "./modules/public/forgot-password/forgot-password.module#ForgotPasswordModule"
  },
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "dashboard",
        loadChildren:
          "./modules/admin/dashboard/dashboard.module#DashboardModule",
        canActivate: [AuthGuard]
      },
      {
        path: "about",
        loadChildren: "./modules/admin/about/about.module#AboutModule",
        canActivate: [AuthGuard]
      },
      {
        path: "doctors",
        loadChildren: "./modules/admin/doctors/doctors.module#DoctorsModule",
        canActivate: [AuthGuard]
      },
      {
        path: "facilities",
        loadChildren:
          "./modules/admin/facilities/facilities.module#FacilitiesModule",
        canActivate: [AuthGuard]
      },
      {
        path: "services",
        loadChildren: "./modules/admin/services/services.module#ServicesModule",
        canActivate: [AuthGuard]
      },
      {
        path: "testimonials",
        loadChildren:
          "./modules/admin/testimonials/testimonials.module#TestimonialsModule",
        canActivate: [AuthGuard]
      },
      {
        path: "messages",
        loadChildren: "./modules/admin/messages/messages.module#MessagesModule",
        canActivate: [AuthGuard]
      },
      {
        path: "featured-cases",
        loadChildren:
          "./modules/admin/featured-cases/featured-cases.module#FeaturedCasesModule",
        canActivate: [AuthGuard]
      },
      {
        path: "profile",
        loadChildren: "./modules/admin/profile/profile.module#ProfileModule",
        canActivate: [AuthGuard]
      },
      {
        path: "mail-list",
        loadChildren: "./modules/admin/mail-list/mail-list.module#MailListModule",
        canActivate: [AuthGuard]
      },
      { path: "**", component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
