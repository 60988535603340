import { Component, OnInit } from "@angular/core";
import { AdminLayoutService } from "./../admin-layout.service";
import { NotificationService } from "./../../../../shared/services/notification.service";
import { StatisticsCountService } from "./../../../../shared/services/statistics-count.service";

@Component({
  selector: "app-admin-stats",
  templateUrl: "./admin-stats.component.html",
  styleUrls: ["./admin-stats.component.scss"]
})
export class AdminStatsComponent implements OnInit {
  doctorCount: Number;
  facilityCount: Number;
  serviceCount: Number;
  messageCount: Number;

  constructor(
    private _adminService: AdminLayoutService,
    private _notificationService: NotificationService,
    private _statisticsCountService: StatisticsCountService
  ) {}

  ngOnInit() {
    this.initialize();
    this.showDoctorCount();
    this.showFacilityCount();
    this.showServiceCount();
    this.showMessageCount();
    this.getFacilityCount();
    this.getServiceCount();
    this.getDoctorCount();
    this.getMessageCount();
  }

  // Initialize
  initialize() {
    this.doctorCount = 0;
    this.facilityCount = 0;
    this.serviceCount = 0;
    this.messageCount = 0;
  }

  // Show doctor count
  showDoctorCount() {
    this._adminService.showDoctorCount().subscribe(
      data => {
        if (data.status == true) {
          this.doctorCount = data.data;
        }
      },
      error => {
        this._notificationService.notificationMessage(
          "danger",
          error.error.message
        );
      }
    );
  }

  // Show facility count
  showFacilityCount() {
    this._adminService.showFacilityCount().subscribe(
      data => {
        if (data.status == true) {
          this.facilityCount = data.data;
        } else {
          this._notificationService.notificationMessage("danger", data.message);
        }
      },
      error => {
        this._notificationService.notificationMessage(
          "danger",
          error.error.message
        );
      }
    );
  }

  // Show service count
  showServiceCount() {
    this._adminService.showServiceCount().subscribe(
      data => {
        if (data.status == true) {
          this.serviceCount = data.data;
        } else {
          this._notificationService.notificationMessage("danger", data.message);
        }
      },
      error => {
        this._notificationService.notificationMessage(
          "danger",
          error.error.message
        );
      }
    );
  }

  // Show service count
  showMessageCount() {
    this._adminService.showMessageCount().subscribe(
      data => {
        if (data.status == true) {
          this.messageCount = data.data;
        } else {
          this._notificationService.notificationMessage("danger", data.message);
        }
      },
      error => {
        this._notificationService.notificationMessage(
          "danger",
          error.error.message
        );
      }
    );
  }

  // Get doctor count
  getDoctorCount() {
    this._statisticsCountService.doctorData.subscribe(count => {
      this.doctorCount = count;
    });
  }

  // Get facility count
  getFacilityCount() {
    this._statisticsCountService.facilityData.subscribe(count => {
      this.facilityCount = count;
    });
  }

  // Get service count
  getServiceCount() {
    this._statisticsCountService.serviceData.subscribe(count => {
      this.serviceCount = count;
    });
  }

  // Get message count
  getMessageCount() {
    this._statisticsCountService.messageData.subscribe(count => {
      this.messageCount = count;
    });
  }
}
