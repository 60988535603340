import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { AdminLayoutModule } from "./layouts/admin/admin-layout/admin-layout.module";
import { AdminComponent } from "./layouts/admin/admin.component";
import { NotFoundComponent } from "./modules/admin/not-found/not-found.component";

import { NotificationService } from "./shared/services/notification.service";
import { TokenInformationService } from "./shared/services/token-information.service";
import { MessageService } from "./shared/services/message.service";
import { StatisticsCountService } from "./shared/services/statistics-count.service";

@NgModule({
  declarations: [AppComponent, AdminComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AdminLayoutModule
  ],
  providers: [
    NotificationService,
    TokenInformationService,
    MessageService,
    StatisticsCountService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
