import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AdminHeaderComponent } from "./admin-header/admin-header.component";
import { AdminStatsComponent } from "./admin-stats/admin-stats.component";
import { AdminFooterComponent } from "./admin-footer/admin-footer.component";
import { AdminLayoutService } from "./admin-layout.service";
import { JwtInterceptor } from "./../../../shared/interceptors/jwt.interceptor";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations: [
    AdminHeaderComponent,
    AdminStatsComponent,
    AdminFooterComponent
  ],
  exports: [
    AdminHeaderComponent,
    AdminStatsComponent,
    AdminFooterComponent
  ],
  providers: [
    AdminLayoutService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ]
})
export class AdminLayoutModule {}
